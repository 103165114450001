(function () {
    angular.module('informaApp')
        .component('tableFilterPopup', {
            templateUrl: 'components/table-filter-popup/template.html',
            controller: TableFilterPopup,
            bindings: {
                filter: '<',
                totalPrograms: '<'
            }
        });

    function TableFilterPopup($element, FilterMapper, FilterService) {
        this.$onInit = () => {
            this.targetElement = $element.find('i')[0];

            this.getSource = getSourceFunction(this, FilterService, FilterMapper);
        }
    }

    function getSourceFunction(context, FilterService, FilterMapper) {
        return () => {
            const filterColumnSize = 3;
            const valueColumnSize = 9;

            return FilterService.getFilterWithNames(context.filter).then(filterWithNames => {
                const mappedFilter = FilterMapper.mapToReadableView(filterWithNames, context.totalPrograms);

                return {
                    sizes: [filterColumnSize, valueColumnSize],
                    headers: ['Filter', 'Values'],
                    rows: mapFilter(mappedFilter)
                };
            })
        };
    }

    function mapFilter(filter) {
        return [
            mapFilterValue(filter.timeRange),

            ...mapFilterSource(filter.selections),
            ...mapFilterSource(filter.regulatorySection),

            mapFilterValue(filter.companyMode),

            ...mapFilterSource(filter.excludedItems),

            mapFilterValue(filter.totalPrograms)
        ]
    }

    function mapFilterSource(filterSource) {
        return filterSource.keys
            .filter(x => ifNeedsToShow(filterSource, x))
            .map(x => mapFilterValue(filterSource.source[x]));
    }

    function ifNeedsToShow(filterSource, key) {
        const ifNotDefaultFlag = (flag) => flag !== 'All';

        const data = filterSource.source[key];

        return data && data.value && ifNotDefaultFlag(data.value);
    }

    function mapFilterValue(data) {
        return [data.title, data.value];
    }
})();